<template>
  <div>
    <v-autocomplete
      v-model="selectedItems"
      :items="items"
      item-text="description"
      :item-value="itemValue"
      :filled="filled"
      :label="label"
      :hide-details="hideDetails"
      :placeholder="placeholder"
      :dense="dense"
      :solo-inverted="soloInverted"
      :multiple="multiple"
      :clearable="clearable"
      :loading="loading"
      :return-object="returnObject"
      :prepend-icon="prependIcon"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :outlined="outlined"
      ref="autocomplete"
    >
      <template slot="append-outer">
        <slot name="append-outer"></slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import itemService from "@/services/warehouse/items.service.js";
import Vue from 'vue'

export default {
  name: "ItemsAutocomplete",
  data: function() {
    let selection;
    if(!!this.value) {
      if(this.multiple && !this.returnObject) {
        selection = []
        for(let i = 0; i < this.value.length; i++) {
          selection.push(this.value[i])
        }
      } else if(!this.returnObject) {
        selection = this.value
      } else {
        selection = this.value
      }
    }

    return {
      items: [],
      selectedItems: selection,
      loading: false
    }
  },
  props: {
    value: {
      default: function() {
        return []
      }
    },
    bus: {
      type: Object,
      default: () => { new Vue() }
    },
    placeholder: {
      type: String,
      default: 'Tutti i prodotti'
    },
    dense: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'Prodotti'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      default: 'auto'
    },
    prependIcon: {
      type: String,
      default: 'mdi-spray-bottle'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    soloInverted: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.loadItems()

    if(!!this.bus) {
      this.bus.$on('reload', () => {
        this.loadItems()
      })
      this.bus.$on('focus', () => {
        this.$nextTick(() => {
          this.$refs.autocomplete.focus()
        })
      })
    }
  },
  methods: {
    loadItems() {
      this.loading = true
    
      itemService.list(undefined, 2000, this.filters).then((results) => {
        this.items = results.rows;
        this.loading = false;
      });
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  },
  watch: {
    value(newVal) {
      this.selectedItems = newVal
    },
    filters() {
      this.loadItems()
    },
    selectedItems(newVal) {
      if(this.multiple && !this.arraysEqual(newVal, this.value)) {
        this.$emit('input', newVal)
      } else if(!this.multiple && newVal != this.value) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style>

</style>